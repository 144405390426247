.briefTest-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
  }
  
  .briefTest-container h2 {
    margin-bottom: 10px;
  }
  
  .briefTest-container p {
    margin-bottom: 10px;
  }
  
  .briefTest-container ul {
    text-align: left;
    margin-bottom: 20px;
  }
  
  .startTest-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .startTest-button:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
  