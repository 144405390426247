/* Reset default styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
    margin: 0;
    padding: 0;
}

/* Home Container */
.home-containerAdmin {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* gap: 2rem; */
    /* padding: 2rem; */
    background-color: #fff;
    width: 100%;
}

/* Header Section */
.home-headerAdmin {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
   
}

.header-imgAdmin {
    width: 100%;
    /* max-width: 1200px; */
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Reviews Section */
.home-reviewsAdmin {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;
}

.reviews-imgAdmin {
    width: 100%;
    /* max-width: 1000px; */
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Contact Section */
.home-contactAdmin {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 1rem 0; */
}

.contact-imgAdmin {
    width: 100%;
    /* max-width: 800px; */
    height: 300px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Responsive Styles */

/* For tablets and small laptops */
@media (max-width: 768px) {
    .home-containerAdmin {
       
    }

    .header-imgAdmin, 
    .reviews-imgAdmin, 
    .contact-imgAdmin {
        /* max-width: 90%;  */
    }
}

/* For mobile screens */
@media (max-width: 480px) {
    .home-containerAdmin {
        /* padding: 0.5rem;
        gap: 1rem; */
        margin-top: 3rem;
    }

    .header-imgAdmin, 
    .reviews-imgAdmin, 
    .contact-imgAdmin {
        /* max-width: 100%;  */
        /* border-radius: 5px;  */
    }

    .contact-imgAdmin{
        height: auto;
    }
}
