/* General Styles */
body {
    background-color: white; /* Light background color */
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
  }
  
  .job-deatil-cont {
    display: flex;
    flex-direction: column;
    width: 70%; /* Default width for larger screens */
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    padding-bottom: 50px;
  }
  
  header h1 {
    text-align: center;
    color: #333;
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .subhead-span {
    margin-bottom: 15px;
    text-align: left;
    font-size: 18px;
  }
  
  .table-container {
    width: 100%;
    border-collapse: collapse;
    border: 2px solid black;
    text-align: center;
    margin: auto;
  }
  
  .table-header {
    text-align: center;
    padding: 10px;
  }
  
  .job-title {
    color: brown;
    margin: 0;
  }
  
  .advt-number {
    color: black;
    margin: 0;
  }
  
  .important-dates-header {
    padding: 10px;
    background-color: white;
    text-align: center;
  }
  
  .important-dates-title {
    color: rgb(180, 11, 76);
    margin: 0;
  }
  
  .important-dates-item {
    margin: 5px 0;
    font-size: 14px;
    text-align: left;
  }
  
  .student-head-row {
    text-align: center;
    padding: 10px;
    background-color: rgb(161, 39, 39);
    color: white;
    border: 2px solid black;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }
  
  .table-imp-link {
    text-align: center;
    padding: 10px;
    background-color: rgb(100, 100, 255);
    color: white;
    border: 2px solid black;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .vacancy-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
  }
  
  .vacancy-table th,
  .vacancy-table td {
    border: 1px solid black;
    padding: 10px;
    text-align: left;
  }
  
  .vacancy-table th {
    background-color: #f2f2f2;
    text-transform: capitalize;
  }
  
  .vacancy-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .vacancy-table tr:hover {
    background-color: #e8f0ff;
  }
  
  .links-row{
    border: 2px black solid;
    height: 40px;
  }
  
  .links-row td{
    padding: 5px;
  }
  
  .links-row:hover{
    background-color: #f2f2f2;
  }
  .vacancy-row{
    border: 2px black solid;
    height: 40px;
  }
  .vacancy-row td{
    border: 2px black solid;
  }
  
  .value-text{
    font-weight: bold;
  }

  .important-dates-item {
    list-style-type: none; /* Remove default list styling */
    margin-bottom: 10px;
  }
  
  .arrow {
    margin-right: 5px;
    font-size: 1.2em;
    color: #007bff; /* You can choose any color for the arrow */
  }
  
  
  
  
  /* Responsive Design */
  @media (max-width: 1024px) {
    .job-deatil-cont {
      width: 90%; /* Adjust width for medium screens */
    }
  
    header h1 {
      font-size: 20px;
    }
  
    .subhead-span {
      font-size: 16px;
    }
  }
  
  @media (max-width: 768px) {
    .job-deatil-cont {
      width: 95%; /* Adjust width for small screens */
    }
  
    header h1 {
      font-size: 18px;
    }
  
    .subhead-span {
      font-size: 14px;
    }
  
    .table-container {
      font-size: 14px;
    }
  }
  
  @media (max-width: 480px) {
    header h1 {
      font-size: 16px;
    }
  
    .subhead-span {
      font-size: 12px;
    }
  
    .table-container {
      font-size: 12px;
    }
  
    .important-dates-item {
      font-size: 12px;
    }
  }
  