/* General Styles */
.university-container {
  font-family: Arial, sans-serif;
  padding: 20px;
  margin: 0 auto;
}

/* Header Section */
.header-search {
  display: flex;
  /* flex-wrap: wrap; */
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
  width: 100%;
}

.univ-header {
  display: flex;
  gap: 10px;
  /* flex-wrap: wrap; */
  justify-content: space-between;
  align-items: center;
  background-color: #333;
  color: white;
  padding: 10px 20px;
  top: 0;
  z-index: 100;
  width: 100%;
}

.univ-header-section {
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 4px;
  background-color: #333;
  color: white;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}

.univ-header-section.selected {
  background-color: #007bff;
  font-weight: bold;
  text-decoration: underline;
}

.res-univ-header {
  display: none;
}

.toggle-search-button {
  padding: 8px 12px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.toggle-search-button:hover {
  background-color: #0056b3;
}

.uni-name{
  border: solid 2px #333;
  padding: 2px;
}

/* Dropdown Section */
.dropdown-row {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 20px;
}

.dropdown-container {
  flex: 1 1 200px;
  display: flex;
  flex-direction: column;
}

.dropdown-container label {
  margin-bottom: 5px;
  font-weight: bold;
}

.selecttag {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.selecttag:disabled {
  background-color: #f5f5f5;
  color: #999;
  cursor: not-allowed;
}

/* Summary Section */
.uni-selection-summary {
  display: flex;
  gap: 20px;
  margin-top: 20px;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
}

.uni-card {
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
}

.uni-card-heading {
  font-weight: bold;
  font-size: 1.1em;
  margin-bottom: 5px;
}

.uni-card-subheading {
  font-size: 0.9em;
  color: #666;
  margin-bottom: 10px;
}

.uni-card-body {
  margin-bottom: 10px;
}

.uni-card-footer .uni-download-button {
  padding: 5px 10px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.uni-card-footer .uni-download-button:hover {
  background-color: #0056b3;
}

/* Responsive Design */
@media (max-width: 768px) {
  .univ-header {
    display: none;
  }

  .univ-header-dropdown {
      width: 100%; /* Dropdown matches the width of its container */
  max-width: 100%; /* Prevents the dropdown from exceeding container width */
  padding: 5px; /* Adds spacing for better usability */
  box-sizing: border-box;
  }

  .res-univ-header {
    display: block;
    margin-bottom: 15px;
    /* width: auto; */
  }
  .res-uni-header-opt{
    /* width: auto; */
    word-wrap: break-word; /* Breaks long text inside the dropdown options */
  overflow-wrap: break-word;
  text-overflow: ellipsis;
  }
  /* .univ-header-dropdown{width: auto;} */

  .dropdown-row {
    flex-direction: column;
    gap: 10px;
  }

  .dropdown-container {
    flex: 1 1 100%;
  }

  .toggle-search-button {
    width: 100px;
    text-align: center;
  }
}

@media (max-width: 480px) {
  .selecttag {
    font-size: 12px;
  }

  .uni-card-heading {
    font-size: 1em;
  }

  .uni-card-subheading {
    font-size: 0.8em;
  }

  .toggle-search-button {
    font-size: 14px;
  }
}
