.news-section-box {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin: 0 auto;
    justify-content: center;
    padding: 20px;
    max-width: 1200px;
    
  }
  
  .news-card {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 100%;
    max-width: 500px;
    padding: 15px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .news-card:hover {
    transform: scale(1.02);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  .news-card-head {
    display: flex;
    gap: 15px;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .news-card-head img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 8px;
  }
  
  .news-card-head div {
    flex: 1;
  }
  
  .news-card-head h3 {
    font-size: 1.2rem;
    margin: 0;
    color: #333;
  }
  
  .news-card-head h4 {
    font-size: 0.9rem;
    margin: 5px 0;
    color: #888;
  }
  
  .news-card-head p {
    font-size: 1rem;
    margin: 0;
    color: #555;
  }
  
  .news-card ul {
    list-style-type: disc;
    margin: 15px 0 0 20px;
    padding: 0;
  }
  
  .news-card ul li {
    margin: 5px 0;
    font-size: 0.95rem;
    color: #555;
  }
  
  .news-card table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 15px;
  }
  
  .news-card table th,
  .news-card table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
  }
  
  .news-card table th {
    background-color: #f8f8f8;
    font-weight: bold;
  }
  
  .news-card table td {
    color: #555;
  }
  
  /* Responsive Styles */
  @media (max-width: 480px) {
    .news-section-box {
      padding: 10px;
    }
  
    .news-card {
      width: 100%;
      padding: 10px;
      font-size: 0.9rem;
      display: flex;
      flex-direction: column;
      gap: 10px; /* Add spacing between sections */
    }
  
    .news-card-head {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px; /* Add spacing between image and text */
    }
  
    .news-card-head img {
      width: 100%;
      height: auto;
      border-radius: 8px; /* Add consistent border radius */
    }
  
    .news-card-head h3 {
      font-size: 1rem;
      margin: 0;
      color: #333;
    }
  
    .news-card-head h4 {
      font-size: 0.85rem;
      margin: 5px 0;
      color: #888;
    }
  
    .news-card-head p {
      font-size: 0.85rem;
      margin: 0;
      color: #555;
    }
  
    .news-card ul {
      list-style-type: disc;
      margin: 10px 0 0 20px;
      padding: 0;
      font-size: 0.85rem;
    }
  
    .news-card ul li {
      margin: 5px 0;
      color: #555;
    }
  
    .news-card table {
      width: 100%;
      border-collapse: collapse;
      margin-top: 10px;
    }
  
    .news-card table th,
    .news-card table td {
      padding: 8px;
      font-size: 0.85rem;
      text-align: left;
    }
  
    .news-card table th {
      background-color: #f8f8f8;
      font-weight: bold;
    }
  
    .news-card table td {
      color: #555;
    }
  
    .news-card h3 {
      font-size: 0.95rem;
      margin-bottom: 5px;
      color: #222;
    }
  }
  
  