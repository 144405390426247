/* Overall page styling */
body {
  margin: 0;
  font-family: Arial, sans-serif;
  
  color: #343a40;
}

/* Container with sidebar and main content */
.test-result-page-container {
  display: flex;
  width: 100%;
  height: 100vh; /* Full viewport height */
  overflow: hidden; /* Prevent unwanted scrollbars */
}

/* Sidebar styling */
.sidebar {
  width: 100px;
  background-color: #f8f9fa;
  padding: 1rem;
  border-right: 1px solid #dee2e6;
  overflow-y: auto; /* Enable scrolling if sidebar content is long */
  box-shadow: 2px 0 6px rgba(0, 0, 0, 0.1);
  display: block;
}

.sidebar h4 {
  font-size: 1.5rem;
  color: #007bff;
  text-align: center;
  margin-bottom: 1rem;
  font-weight: bold;
}

.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar-item {
  padding: 0.8rem 1rem;
  margin-bottom: 0.5rem;
  border-radius: 4px;
  background-color: #e9ecef;
  color: #495057;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.sidebar-item.active,
.sidebar-item:hover {
  background-color: #007bff;
  color: white;
  font-weight: bold;
}

/* Main content area */
.test-result-page {
  flex: 1;
  padding: 2rem;
  background-color: #ffffff;
  overflow-y: auto; /* Allow scrolling for main content */
  box-shadow: -2px 0 6px rgba(0, 0, 0, 0.1);
}

/* Header styling */
.test-header {
  text-align: center;
  margin-bottom: 2rem;
}

.test-header h2 {
  font-size: 2rem;
  color: #343a40;
}

.test-header h3 {
  font-size: 1.5rem;
  color: #495057;
}

.test-header h4 {
  font-size: 1.2rem;
  font-weight: bold;
  color: #007bff;
}

/* Results container */
.test-results {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

/* Question block styling */
.result-question {
  background-color: #ffffff;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.result-question:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* Question text */
.question-text {
  margin-bottom: 1rem;
  display: flex;
  gap: 20px;
  
}

.question-numberTR {
  font-weight: bold;
  color: #007bff;
  white-space: nowrap;
}

.question-content {
  margin-top: 0.5rem;
  font-size: 1rem;
  color: #495057;
}

/* Options styling */
.options {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.option {
  padding: 0.8rem;
  border-radius: 4px;
  background-color: #e9ecef;
  text-align: center;
  color: #495057;
  cursor: pointer;
  transition: background-color 0.2s ease, transform 0.2s ease;
}

.option.correct {
  background-color: #28a745; /* Green for correct answer */
  color: white;
}

.option.incorrect {
  background-color: #dc3545; /* Red for incorrect answer */
  color: white;
}

.option:hover {
  background-color: #ced4da;
  transform: scale(1.03);
}

/* Selected and correct answer text */
.selected-option,
.correct-option {
  margin-top: 1rem;
}

.selected-option p,
.correct-option p {
  font-size: 0.95rem;
}

/* Solution toggle button */
.solution-toggle {
  background-color: #007bff;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  margin-top: 1rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.solution-toggle:hover {
  background-color: #0056b3;
}

/* Solution text */
.solution-text {
  margin-top: 1rem;
  padding: 1rem;
  background-color: #f8f9fa;
  border-left: 4px solid #007bff;
  border-radius: 4px;
  font-size: 0.95rem;
}

/* Images */
.question-image,
.option img,
.solution-image {
  max-width: 600px;
  height: 200px;
  border-radius: 4px;
  margin-top: 0.5rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .test-result-page-container {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid #dee2e6;
    
  }

  .test-result-page {
    padding: 1rem;
  }
}

@media (max-width: 480px) {
  .test-header h2 {
    font-size: 1.5rem;
  }

  .test-header h3 {
    font-size: 1.2rem;
  }

  .test-header h4 {
    font-size: 1rem;
  }
}
