/* Main container layout */
.uniexam-container {
  display: grid;
  grid-template-columns: 250px 1fr;
  gap: 20px;
  padding: 20px;
  background-color: #f5f9fc;
  height: 100vh;
}

/* Sidebar styling */
.side-uni {
  background-color: gray;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  width: 180px;
  margin-bottom: 20px;
}

/* Sidebar item styling */
.uni-sidebar-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0px;
  margin-bottom: 5px;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  transition: background-color 0.2s;
  margin: 20px 0px;
  gap: 10px;
}

.uni-sidebar-item:hover {
  background-color: #f0e1e1;
}

.icon {
  font-size: 20px;
  margin-right: 10px;
}

.name {
  flex-grow: 1;
}

.arrow {
  font-size: 16px;
}

/* Sub-sidebar styling */
.subStateExam {
  position: absolute;
  top: 0;
  left: 101%;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 10px;
  display: none;
  width: 180px;
}

.uni-sidebar-item:hover .subStateExam {
  display: block;
}

.subStateExam ul {
  list-style: none;
  padding: 0;
}

.subStateExam li {
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.subStateExam li:hover {
  background-color: #f0f0f0;
}

/* Exam grid styling */
.exam-grid-uni {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px;
}

.exam-item-container {
  height: 250px;
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 20px;
}

.exam-item-container:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.exam-item-container h3 {
  margin: 0;
  padding: 15px;
  font-size: 1.2rem;
  color: #333;
  background-color: #f3f3f3;
  border-bottom: 1px solid #e0e0e0;
}

.exam-item {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  color: #007bff;
  text-decoration: none;
  margin-top: auto; /* Push to the bottom */
  transition: color 0.3s;
}

.exam-item:hover {
  color: #0056b3;
}



/* Dropdown container and styling */
.dropdown-uni {
  display: none;
  margin-bottom: 20px;
}

.uni-dropdown {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
  outline: none;
  cursor: pointer;
}

.dropdown-heading {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .uniexam-container {
      grid-template-columns: 1fr;
      grid-template-rows: auto 1fr;
  }

  .sidebar {
      width: 100%;
      margin-bottom: 20px;
  }
}

@media (max-width: 480px) {
  .exam-grid-uni {
      grid-template-columns: 1fr;
  }

  .dropdown-uni {
      display: block;
  }

  .side-uni {
      display: none;
  }

  .uniexam-container {
      display: flex;
      flex-direction: column;
  }

  .uni-dropdown {
      width: auto;
  }
}
