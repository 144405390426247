/* Header container */
.testtypeheader {
    width: 100%;
    height: 50px;
    background-color: blueviolet;
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    scroll-behavior: smooth;
  }

  

  .loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-size: 1.5rem;
    font-weight: bold;
    color: #555;
  }
  
  
  /* Individual test type item */
  .testtype-item {
    min-width: 120px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
    cursor: pointer;
    padding: 0 15px;
    transition: background-color 0.2s;
  }
  
  .testtype-item:hover {
    background-color: darkviolet;
  }
  
  /* Paragraph text in item cards */
  /* p {
    padding: 20px;
    margin-right: 5px;
    white-space: normal; 
    word-wrap: break-word; 
    overflow-wrap: break-word; 
  } */
  
  /* Section to display list items for selected test type */
  .examTypeListSect {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
  }
  
  /* Individual card in the list */
  .examTypeListCard {
    padding: 15px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    color: #333;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
  }
  
  .examTypeListCard h3 {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 10px;
  }
  
  .examTypeListCard div {
    font-size: 0.9rem;
    color: #777;
  }
  
  .examTypeListCard button {
    padding: 8px 15px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100px;
    
  }
  
  .examTypeListCard button:hover {
    background-color: #0056b3;
  }


/* ==================drop dwon==================== */

.selectBtn {
  display: flex;
  justify-content: space-around; /* Horizontally align with spacing between buttons */
  align-items: center;          /* Vertically center the items */
  margin: 10px 0;               /* Add margin on top and bottom only */
}

.dropdown-button {
  display: none; /* Hidden by default */
  background-color: #007bff;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;
  position: relative;
}


/* Dropdown Menu */
.dropdown-menu {
  display: none; 
  position: absolute; 
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 10;
  width: 130px;
  left: 33%;
  top: 12%;
}

.dropdown-menu.open {
  display: block; /* Make visible when 'open' class is applied */
}

.dropdown-item {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}

.dropdown-item.selected {
  background-color: #007bff;
  
  color: white;
}

  
  /* Responsive styling for smaller screens */
  @media (max-width: 768px) {
    .testtypeheader {
      overflow-x: scroll; /* Allow horizontal scroll */
    }
  
    .testtype-item {
      min-width: 100px; /* Adjust width of items */
    }
  
    .examTypeListSect {
      padding: 5px; /* Reduce padding on smaller screens */
    }
  
    .examTypeListCard {
      padding: 10px;
      font-size: 0.9rem; /* Slightly smaller font for mobile */
    }
  }

  @media (max-width: 480px) {
    .dropdown-button {
      display: block; /* Show dropdown button on small screens */
    }
  
    .dropdown-menu {
      display: block; /* Show dropdown menu when triggered */
    }
  
    .testtypeheader {
      display: none; /* Hide test type items on small screens */
    }
   
    
  }
  
  /* Hide scrollbar on Webkit browsers */
  .testtypeheader::-webkit-scrollbar {
    display: none;
  }
  
  /* Optional: Hide scrollbar on Firefox */
  .testtypeheader {
    scrollbar-width: none;
  }
  