/* General Styling */
body {
    margin: 0;
    font-family: Arial, sans-serif;
  }

 
  .news-container {
    display: flex;
    flex-direction: column;
    margin-left: 1%;
    
  }
  
  /* Header Styles */
  .news-header {
    display: flex;
    justify-content: center;
    background-color: #333;
    padding: 10px;
    margin-right: 2%;
    
    
  }
  
  .news-header-item {
    color: white;
    margin: 0 10px;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .news-header-item.selected {
    background-color: #007bff;
  }
  
  .news-header-item:hover {
    background-color: #0056b3;
  }
  
  /* Sub-header Styles */
  .sub-news-header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: #f8f9fa;
    border-bottom: 1px solid #ccc;
    gap: 10px;
    height: 50px;
  }
  
  .category-select,
  .date-input,
  .go-button
   {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
  }
  
  .category-select,
  .date-input {
    width: 150px;
  }
  
  .go-button
   {
    background-color: #007bff;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .go-button:hover{
    background-color: #0056b3;
  }
  .sub-main-news{
    display: flex;  
    justify-content: space-between;
    margin-right: 3%;
  }
  
  /* Content Layout */
  .news-content {
    display: flex;
    flex: 1;
  
   
}

/* Sidebar Styles */
.news-sidebar {
      height: 300px;
    width: 100px;
    background-color: #f4f4f4;
    padding: 10px;
    border-right: 1px solid #ccc;
  }
  
  .news-sidebar-item {
    padding: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .news-sidebar-item.selected {
    background-color: #007bff;
    color: white;
  }
  
  .news-sidebar-item:hover {
    background-color: #0056b3;
    color: white;
  }
  
  /* Main Content Styles */
  .news-main {
    flex: 1;
    padding: 20px;
  }
  
  .news-main h2 {
    margin-top: 0;
  }

  /* General Styles */
.sub-res-news-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    background-color: #f8f9fa;
    border-bottom: 1px solid #ccc;
    display: none;
    width: 70%;
    
  }
  
  .filter-res-toggle-button {
    padding: 10px;
    width: 100%;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .filter-res-toggle-button:hover {
    background-color: #0056b3;
  }
  
  .res-cat-table {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
    margin-top: 10px;
  }
  
  .res-category-select,
  .res-date-input,
  .res-go-button {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .res-go-button {
    background-color: #007bff;
    color: white;
    cursor: pointer;
  }
  
  .res-go-button:hover {
    background-color: #0056b3;
  }


  .filter-container {
    position: relative;
    display: inline-block;
    
  }
  
  .filter-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  .filter-button:hover {
    background-color: #0056b3;
  }
  
  .filter-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #f8f9fa;
    border: 1px solid #ccc;
    border-radius: 5px;
    list-style: none;
    padding: 0;
    margin: 10px 0 0;
    width: 100%;
    z-index: 1000;
  }
  
  .filter-option {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .filter-option:hover {
    background-color: #007bff;
    color: white;
  }
  
  .selected-filter {
    margin-top: 10px;
    font-size: 14px;
  }
  

  .date-selector {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: 40%;
  }
  
  .arrow-button {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .arrow-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .date-display {
    position: relative;
    font-size: 1rem;
  }
  
  .date-display input[type="date"] {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 8px 12px;
    font-size: 1rem;
    width: 150px;
    text-align: center;
    cursor: pointer;
  }
  
  .date-display input[type="date"]::-webkit-calendar-picker-indicator {
    cursor: pointer;
  }
  
  
  
  /* Responsive styles for screens 480px and below */
  @media (max-width: 480px) {
    .sub-res-news-header {
      padding: 5px;
      display: block;
      justify-content: space-between;
    }

    
  
    .filter-res-toggle-button {
      font-size: 14px;
      padding: 8px;
    }
  
    .res-cat-table {
      gap: 8px;
    }
  
    .res-category-select,
    .res-date-input,
    .res-go-button {
      font-size: 12px;
      padding: 8px;
    }
  
    .res-go-button {
      font-size: 14px;
    }
 
    .news-content {
      display: flex;
    }

    .filter-container {
        justify-content: flex-end;
        
        
      }
  
    .news-sidebar {
      width: 30%;
      border-right: none;
      border-bottom: 1px solid #ccc;
    }
  
    .news-sidebar-item {
      text-align: center;
    }
  
    .sub-news-header {
      flex-direction: column;
      gap: 5px;
      display: none;
    }
    .sub-main-news{
        display: flex;
        /* flex-direction: column; */
        justify-content: space-between;
        
      }
      
  }
  