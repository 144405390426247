.test-page {
    display: flex;
    flex-direction: column;
    font-family: Arial, sans-serif;
  }
  
  .test-header h2, .test-header h3 {
    font-size: 1em;
    margin: 0;
}
  
  .timer {
    font-weight: bold;
    color: #333;
  }
  
  .test-content {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
}
  
  .question-area {
    flex: 3;
    padding: 20px;
    border-right: 2px solid #ddd;
  }
  
  .question-header h4 {
    font-size: 1.2em;
    margin-bottom: 10px;
}

  
  .options {
    display: flex;
    flex-direction: column;
  }
  
  .option {
    margin: 5px 0;
    padding: 10px;
    background-color: #e0e0e0;
    border: 1px solid #ccc;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .option.selected {
    background-color: #007bff;
    color: white;
  }
  
  .question-controls {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 20px;
}

.question-image, .option-image {
  width: 100%;        /* Make image width responsive */
  /* max-width: 30rem;   Set a maximum width to avoid being too large */
  height: auto;  
  height: auto;
  display: block;
}
  
  .next-button, .mark-button, .clear-button {
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 4px;
  }
  
  /* Sidebar Toggle Button */
.sidebar-toggle {
  display: none;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

/* Sidebar */
.sidebarQuest {
  flex: 1;
  padding: 20px;
  background-color: #f1f1f1;
  transition: transform 0.3s ease;
}

.sidebarQuest.open {
  transform: translateX(0); /* Slide-in on small screens */
}

  
  .profile-info {
    font-size: 14px;
    margin-bottom: 20px;
  }
  
  .question-list {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
  }
  
  .question-number {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ddd;
    border: none;
    cursor: pointer;
  }
  
  .question-number.marked {
    background-color: #28a745;
    color: white;
  }
  
  .submit-button {
    margin-top: 20px;
    padding: 10px;
    width: 100%;
    background-color: #28a745;
    color: white;
    border: none;
    cursor: pointer;
  }

  .question-text {
    display: flex;
    align-items: center;
    padding-left: 20px;
  }
  
  .question-number {
    white-space: rap;
    margin-right: 10px; 
    font-weight: bold;
  }
  
  /* .question-content {
    display: inline; 
  }


  .correct {
    background-color: green;
    color: white;
  }
  
  .incorrect {
    background-color: red;
    color: white;
  } */
  
  .option {
    padding: 8px;
    margin-bottom: 5px;
    border-radius: 4px;
    margin-left: 10px;
    margin-right: 10px;
  }
  
  .option.selected {
    border: 2px solid #007bff; /* Optional: Highlight selected option */
  }
  


/* Responsive Styles for Small Screens */
@media (max-width: 768px) {
  .test-content {
      flex-direction: column;
  }

  .question-area {
      padding: 10px;
  }

  .test-header {
      flex-direction: column;
      align-items: center;
      text-align: center;
  }

  .test-header h2, .test-header h3 {
      font-size: 1em;
  }

  .options .option {
      font-size: 0.9em;
      padding: 8px;
  }

  .sidebarQuest {
    position: absolute;
    top: 0;
    right: 0;
    width: 60%; /* Adjust width for mobile */
    height: 100%;
    transform: translateX(100%); /* Initially hidden */
    z-index: 10;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
}

.sidebar-toggle {
    display: inline-block;
}

/* Sidebar Open State */
.sidebarQuest.open {
    transform: translateX(0); /* Slide-in effect */
}

  .next-button, .mark-button, .clear-button {
      flex: 1;
      padding: 10px;
      width: 100%;
      font-size: 0.9em;
  }

  .submit-button {
      font-size: 1em;
      padding: 12px;
  }

  .question-number {
      width: 30px;
      height: 30px;
      font-size: 0.8em;
  }
}

/* Extra small screens (e.g., Android phones in portrait mode) */
@media (max-width: 480px) {
  .test-header {
      padding: 5px 10px;
  }

  .test-header h2, .test-header h3 {
      font-size: 0.9em;
  }

  .question-area {
      padding: 5px;
  }

  .question-header h4 {
      font-size: 1em;
  }

  .question-controls {
      flex-direction: column;
      gap: 5px;
  }

  .options .option {
      font-size: 0.8em;
      padding: 6px;
  }

  .question-number {
      width: 25px;
      height: 25px;
      font-size: 0.7em;
  }

  .submit-button {
      padding: 10px;
  }
}
  
  