/* Basic Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Main container */
.exam-selector-container {
  font-family: 'Arial', sans-serif;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

/* Heading styles */
h1 {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 20px;
}

/* Select row layout */
.select-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 15px;
  margin-bottom: 20px;
}

.select-row select,
.select-row button {
  padding: 10px;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%;
  max-width: 250px;
}

.select-row button {
  background-color: #007BFF;
  color: white;
  cursor: pointer;
  border: none;
  transition: background-color 0.3s;
}

.select-row button:hover {
  background-color: #0056b3;
}

.select-row select:disabled {
  background-color: #f0f0f0;
}

/* Loader */
.loader {
  text-align: center;
  font-size: 1.2rem;
  color: #007BFF;
  margin-top: 20px;
}

/* Error message */
.error-message {
  text-align: center;
  color: #ff0000;
  margin-top: 20px;
}

/* Results Section */
.results {
  margin-top: 30px;
}

.result-item {
  background-color: #f9f9f9;
  padding: 20px;
  margin-bottom: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.result-item h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.result-item span {
  display: block;
  margin-bottom: 10px;
  color: #555;
}

.result-item button {
  background-color: #28a745;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.result-item button:hover {
  background-color: #218838;
}

/* Responsive Design */
@media (max-width: 768px) {
  .select-row {
    flex-direction: column;
    align-items: stretch;
  }

  .select-row select,
  .select-row button {
    max-width: 100%;
  }

  .result-item {
    padding: 15px;
  }
}

/* Other CSS remains the same */
/* Add styles for the toggle button */
.toggle-form-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  margin-bottom: 20px;
  transition: background-color 0.3s;
}

.toggle-form-button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .select-row {
    flex-direction: column;
    align-items: stretch;
  }

  .exam-selector-container {
    padding: 10px;
  }

  .results {
    padding: 10px;
  }
}
