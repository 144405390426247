.headerNav{
    width: 100%;
    display: grid;
    grid-template-columns: 5rem 0.4fr 2fr 0.5fr 3rem;
    background-color: #d8cc78;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    justify-content: center;
    align-items: center;
    /* padding: 0.5rem 1rem; */
}



.sidebar-toggle-icon{display: none;}


.logo {
    height: 2.5rem; /* Adjust for your layout */
    display: flex; /* Use flexbox for alignment */
    justify-content: start; /* Align content to the start (left) */
    align-items: center; /* Vertically center the content */
    background-color: white; /* Background color for contrast */
    padding: 2px 5px; /* Add horizontal padding for spacing */
    border-radius: 10px; /* Smooth rounded corners */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06); /* Subtle card shadow */
    border: 1px solid #ddd; /* Light border for definition */
    transition: transform 0.2s ease, box-shadow 0.2s ease; /* Smooth hover animations */
    grid-column: 2/3; /* For grid layout */
    width: 160px;
}

.logo:hover {
    transform: translateY(-2px); /* Slight lift on hover */
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15), 0 2px 4px rgba(0, 0, 0, 0.1); /* Enhanced shadow */
  }

.logoimg {
    width: 50px; /* Set the width for the image */
    height: auto; /* Maintain aspect ratio */
    margin-right: 8px; /* Add space between the image and text */
}

.logo a {
    color: #333; /* Text color */
    text-decoration: none; /* Remove underline */
    font-weight: bold; /* Make text bold */
    display: flex; /* Ensure the text aligns well */
    align-items: center; /* Vertically align text with the image */
}


.menu-link-header {
    grid-column: 3/4;
    position: relative;
}

.menu-link-header ul {
    height: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.menu-link-header ul li {
    font-size: 0.8rem;
    list-style: none;
    text-align: center;
    
}

.menu-link-header ul li a {
    text-decoration: none;
    text-transform: capitalize;
    color: #282d31;
    font-weight: bold;
    font-size: 1.2rem;
    
}

.menu-link-header ul li a:hover {
    color: rgb(133, 9, 56);
}


.user {
    height: 3rem;
    display: flex;
    grid-column: 4 / 5;
    align-items: center;
    justify-content: flex-end;
    position: relative; /* Position relative for dropdown alignment */
}

.userLogo1 {
    height: 2.5rem;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid black;
    cursor: pointer; /* Cursor pointer for indication */
}

.userLogo:hover {
    transform: scale(1.1); /* Slightly enlarge on hover */
    border-color: #007bff; /* Change border color on hover */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); /* Add a shadow on hover */
}

.userList {
    position: absolute;
    top: 90%; /* Position dropdown below the user image */
    right: -20%;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    padding: 0.5rem 1rem;
    margin-top: 0.5rem;
    z-index: 10; /* Ensure it appears above other elements */
     /* Ensure there's enough space for the text */
}

.userList ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.userList li {
    white-space: nowrap; /* Prevents text from wrapping to the next line */
    padding: 0.5rem 0;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.userList li:hover {
    background-color: #f0f0f0; /* Highlight on hover */
}

.sidebar{display: none;}




.sub-head-examList {
    position: absolute;
    top: 180%; /* Place below the parent menu item */
    left: 0;
    background-color: #fff;
    border: 1px solid #ddd;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 900;
    width: max-content;
    height: 80px;
}

.sub-head-examList .subExamList {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    list-style: none;
    left: 2%;
}

.sub-head-examList .subExamList li {
    padding: 0.5rem;
}

.sub-head-examList .subExamList li a {
    text-decoration: none;
    color: black;
    font-size: 0.8rem;
}

.sub-head-examList .subExamList li a:hover {
    color: #007bff;
}

.examOpt {
    font-size: 2.25rem; /* Increase the size for "Exam" text */
    font-weight: bold; /* Optionally make it bold to emphasize */
    color: #282d31;
    cursor: pointer; /* Indicate interactivity */
}

.examOpt:hover{
    color :rgb(133, 9, 56);
}







@media (max-width:900px) {

    .sidebar-toggle-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 10px; /* Smooth rounded corners */
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06); /* Subtle card shadow */
        border: 1px solid #ddd; /* Light border for definition */
        transition: transform 0.2s ease, box-shadow 0.2s ease; /* Smooth hover animations */
       margin-right: 10px;
       margin-left: 10px;
       padding: 5px 0;

    }
    .menu-link-header{
        display: none;
    }

    .headerNav{
        height: 3rem;
    }

    .userLogo1{
        height: 2rem;
    }


    
    .sidebar1 {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 150px;
        background-color: #333;
        color: white;
        padding-top: 5rem;
        transition: transform 0.3s ease;
        z-index: 999;
    }

    
    .side-menu-link{
        position: relative;
    }

    .side-menu-link ul {
        list-style: none;
        padding: 0;
    }

    .side-menu-link ul li {
        padding: 0.5rem 1rem;
        list-style: none;
        
    }

    .side-menu-link ul li a{
        text-decoration: none;
        color: white;
    }

    .side-menu-link ul li:hover {
        background-color: #555;
    }

    .sub-side-examList {
        margin-left: 1rem;
    }

    .sub-side-examList ul {
        list-style: none;
        padding-left: 0.5rem;
    }

    .sub-side-examList li a {
        color: white;
        text-decoration: none;
    }

    .sub-side-examList li a:hover {
        color: #ffb3b3;
    }


    .sub-side-examList {
        position: absolute;
        left: 85%;   /* Position relative to the parent list item */
        top: 0%;     /* Align it to the top of the parent */
        background-color: gray; /* Add background-color */
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        padding: 0.5rem;
        border-radius: 4px;
        z-index: 100;
    
    }



    .sub-side-examList ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .sub-side-examList li {
        padding: 0.5rem;
    }

    .sub-side-examList li a {
        text-decoration: none;
        color: black;
    }

    .sub-side-examList li a:hover {
        color: #007bff; /* Optional: Add hover color change */
    }

    
}

