/* Main cont layout */
.govtexam-contG_E {
    display: grid;
    grid-template-columns: 250px 1fr;
    gap: 20px;
    padding: 20px;
    background-color: #f5f9fc;
    height: 100vh;
  }
  .subStateExamG_E li.selected {
    font-weight: bold;
    background-color: #e0e0e0;
  }
  
  /* Sidebar styling */
  .sideGovtG_E1 {
    position: relative;
    background-color: gray;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    width: 200px;
    margin-bottom: 20px;
    height: 1000px;
  }
  
  /* Sidebar item styling */
  .sidebar-itemGovtG_E {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    margin-bottom: 5px;
    border-radius: 4px;
    cursor: pointer;
    position: relative;
    transition: background-color 0.2s;
  }
  
  .sidebar-itemGovtG_E:hover {
    background-color: #f0f0f0;
  }
  
  .iconG_E {
    font-size: 20px;
    margin-right: 10px;
  }
  
  .nameG_E {
    flex-grow: 1;
  }
  
  .arrowG_E {
    font-size: 16px;
  }

  .exam-itemG_E span:hover{
    color: white;
  }


/* Sub-sidebar styling */
.subStateExamGovtG_E1 {
  position: absolute; /* Ensures it overlays properly */
  top: 0; /* Aligns with the top of the parent */
  left: 100%; /* Positions it to the right of its parent */
  background-color: #f8f9fa; /* Light background for visibility */
  border: 1px solid #ddd; /* Subtle border to define the section */
  border-radius: 8px; /* Smooth corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Slight shadow for depth */
  padding: 10px; 
  z-index: 100; 
  width: 200px; 
  white-space: nowrap; 
}

/* Adjustments for list items */
.subStateExamGovtG_E1 ul {
  list-style-type: none; /* Remove default list bullets */
  margin: 0; /* Remove default margins */
  padding: 0; /* Remove default padding */
}

.subStateExamGovtG_E1 li {
  padding: 8px 12px; /* Spacing for list items */
  cursor: pointer; /* Indicates interactivity */
  color: #333; /* Text color */
  font-size: 14px; /* Adjust font size */
  border-bottom: 1px solid #ddd; /* Separator between items */
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth hover effects */
}

.subStateExamGovtG_E1 li:last-child {
  border-bottom: none; /* Remove border for the last item */
}

.subStateExamGovtG_E1 li:hover {
  background-color: #e9ecef; /* Highlight on hover */
  color: #007bff; /* Text color change on hover */
}

/* Positioning relative to the parent cont */
.sideGovtG_E1 {
  position: relative; /* Required for the child absolute positioning to work */
  display: inline-block; /* Ensures the sub-sidebar aligns properly */
}


  

/* Main cont for the exam grid */
.exam-gridG_E {
  display: grid; /* Enables grid layout */
  gap: 20px; /* Space between grid items */
  padding: 20px; /* Inner padding for the cont */
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Responsive column layout */
  justify-items: center; /* Centers items horizontally */
  align-items: start; /* Aligns items to the top */
  background-color: #f9f9f9; /* Light background for contrast */
  border-radius: 8px; /* Smooth corners */
}

/* Card styling */
.exam-cardG_E {
  background-color: #fff; /* White card background */
  border: 1px solid #ddd; /* Subtle border */
  border-radius: 10px; /* Rounded edges */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  padding: 16px; /* Inner padding */
  text-align: center; /* Center-align text */
  width: 100%; /* Full width within grid */
  max-width: 300px; /* Limit maximum width */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth hover effects */
}

/* Hover effects for cards */
.exam-cardG_E:hover {
  transform: translateY(-5px); /* Slight upward lift */
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15); /* Enhanced shadow */
}

/* Card header styling */
.exam-cardG_E h3 {
  margin: 0 0 10px; /* Space below the header */
  font-size: 18px; /* Font size for headings */
  color: #333; /* Dark text color */
}

/* Card content styling */
.exam-cardG_E p {
  margin: 5px 0; /* Space between paragraphs */
  font-size: 14px; /* Font size for content */
  color: #666; /* Subtle text color */
}

/* Link styling inside cards */
.exam-itemG_E {
  display: inline-block;
  margin-top: 10px; /* Space above the link */
  padding: 8px 12px; /* Padding for the button */
  background-color: #007bff; 
  color: #fff; /* White text */
  text-decoration: none; /* Remove underline */
  border-radius: 5px; /* Smooth corners */
  font-size: 14px; /* Adjust font size */
  transition: background-color 0.3s ease; /* Smooth hover effect */
}

.exam-itemG_E:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

/* No selection message styling */
.no-selectionG_E {
  grid-column: span 2; /* Span across two columns in smaller layouts */
  text-align: center; /* Center the message */
  color: #888; /* Subtle text color */
  font-size: 16px; /* Font size for the message */
  margin-top: 20px; /* Space above the message */
}

/* Dropdown styles */
.dropdown-contG_E {
  position: relative;
}

.dropdown-buttonG_E {
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.dropdown-menuG_E{
    width: 170px;
    background-color: #888;
    position: absolute;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06); /* Subtle card shadow */
    border: 1px solid #422d2d; /* Light border for definition */
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    
}


/* Sub-Dropdown Menu */
.sub-dropdown-menuG_E {
    position: absolute;
    top: 100%; /* Aligns with the top of the parent dropdown */
    left: 50%; /* Position to the right of the main dropdown menu */
    margin-left: 5px; /* Optional spacing between the menus */
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 10;
    width: 150px; /* Set the desired width */
  }
  

/* Dropdown Items */
.dropdown-itemG_E, .sub-dropdown-itemG_E {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.dropdown-itemG_E:hover, .sub-dropdown-itemG_E:hover {
  background-color: #f0f0f0;
}

.dropdown-contG_E{
  display: none;
}

.exam-itemG_E {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  color: #007bff;
  text-decoration: underline;
  transition: color 0.3s ease;
}

.exam-itemG_E:hover {
  color: #0056b3;
}







@media (max-width: 800px) {
  .dropdown-contG_E{
    display: block;
  }
  .exam-gridG_E {
    grid-template-columns: 1fr; /* Single-column layout for very small screens */
    padding: 10px; /* Reduce padding */
  }

  .exam-cardG_E {
    max-width: none; /* Remove max-width restriction */
  }
  .sideGovtG_E1{
    display: none;
  }

  
}

  



  
  /* Responsive Design */
  @media (max-width: 768px) {
    .govtexam-contG_E {
      grid-template-columns: 1fr;
      grid-template-rows: auto 1fr;
    }
  
    .sidebarG_E {
      width: 100%;
      margin-bottom: 20px;
    }

    .exam-gridG_E {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Adjust column size for smaller screens */
      gap: 15px; /* Reduce spacing */
    }
  
    .exam-cardG_E {
      padding: 12px; /* Reduce padding */
    }
  
    .exam-cardG_E h3 {
      font-size: 16px; /* Adjust font size */
    }
  
    .exam-cardG_E p {
      font-size: 12px; /* Adjust font size */
    }
  
    .exam-itemG_E {
      font-size: 12px; /* Smaller font size for buttons */
    }
  }
  
 
  