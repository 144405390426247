/* GovtJob Container */
.govt-job-container {
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
}

/* Latest News Box */
.latest-news-box {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 15px;
  margin-bottom: 15px;
}

/* Header Styling */
.latest-news-header {
  background: linear-gradient(135deg, #007bff, #6f42c1, #fd007f);
  color: #fff;
  padding: 10px 15px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  border-radius: 4px 4px 0 0;
}

/* News Grid */
.news-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 10px;
  margin-top: 10px;
}

/* News Item */
.news-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  color: #fff;
  margin-bottom: 10px;
  width: 200px;
  text-align: center;
}
.news-item:hover {
  transform: scale(1.05);
  background-color: #333; /* Darken the color slightly on hover */
}

/* Title and Action */
.news-title {
  font-size: 16px;
  margin-bottom: 5px;
}

.news-action {
  font-size: 14px;
  font-weight: normal;
}

/* Button Styling */
.view-all-button {
  display: block;
  /* width: 100%; */
  margin-top: 15px;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.view-all-button:hover {
  background-color: #0056b3;
}

/* Government Jobs Table */
.government-jobs-table {
  width: 100%;
  margin: 20px 0;
}

.job-category {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 15px;
  margin-bottom: 15px;
}

.job-category-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.job-category-head h2 {
  color: #004080;
}

.job-category-head h2:nth-of-type(2) {
  color: #964B00;
  text-decoration: underline;
  cursor: pointer;
}

.job-category-head h2:nth-of-type(2):hover {
  color: #311b04;
}

/* Table Styling */
.table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.thead th {
  background-color: #004080;
  color: white;
  text-align: left;
  padding: 10px;
}

.tbody td {
  border: 1px solid #ddd;
  padding: 10px;
}

.tbody tr:hover {
  background-color: #f2f2f2;
}

/* Links Styling */
.a-link {
  color: white;
  text-decoration: none;
  background-color: yellowgreen;
  border-radius: 5px;

}

.a-link:hover {
  text-decoration: underline;
}


.news-info {
  flex: 1;
}

.news-icon {
  position: relative;
  font-size: 24px;
  cursor: pointer;
  color: #fff;
  background-color: rgb(192, 47, 47);
  top: -25px;
  left: 5px;
}

.add-news-button {
  background-color: #007bff;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.add-news-button:hover {
  background-color: #0056b3;
}

.delete-button {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.delete-button:hover {
  background-color: #611019;
}

.tbody-data-row{
  text-align: center;
}
.thead-data-row th{
  text-align: center;
}
@media  screen and (max-width:800px) {
  .qulifi-sect{
    display: none;
  }
}

/* Responsive Styles */
@media screen and (max-width: 480px) {
  .govt-job-container {
    padding: 10px;
  }

  .news-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .job-category-head {
    flex-direction: column;
    align-items: flex-start;
  }

  table {
    font-size: 0.8rem;
  }

  th,
  td {
    padding: 8px;
  }

  .view-all-button {
    font-size: 0.8rem;
    padding: 8px 10px;
  }
  .row-recrue{
    display: none;
  }
  .head-recru{
    display: none;

  }
  
}
