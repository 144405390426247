* body{padding: 0; margin: 0;

    
    height: calc(100% - 55px);
}



.quiz-box-page {
    margin-top: 55px;
   
    display: flex;
    flex-direction: column;
    padding-bottom: 100px;
}

.quiz-bar {
    position: relative;
    top: 10px;
    background-color: blueviolet;
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.quiz-bar button {
    font-size: 25px;
}

.quiz-body{
    display: flex;
    flex-direction: column;
}

.create-quiz-btn {
    font-size: 30px;
    margin-top: 25px;
    margin-bottom: 25px;
    width: 300px;
    background-color: rgb(28, 146, 95);
}



/* ================ quiz- dialog ===============*/



.create-quiz-dialog {
    display: flex; /* Flexbox for alignment */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Center horizontally and vertically */
    background-color: #f5f5f5;
    border-radius: 10px;
    padding: 20px;
    width: 400px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
    z-index: 500;
}


.create-quiz-form {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.create-quiz-form label {
    font-size: 16px;
    margin-bottom: 5px;
    color: #333;
}

.create-quiz-form input,
.create-quiz-form select,
.create-quiz-form textarea {
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    width: 100%;
    box-sizing: border-box;
}

.submit-btn {
    background-color: #4CAF50;
    color: white;
    padding: 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.submit-btn:hover {
    background-color: #45a049;
}




.select-exam-quiz {
    height: 65px;
    background-color: #45a049;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}


.select-exam-quiz button{
    
    font-size: 20px;

}


.fa-rectangle-xmark {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 25px;
}

.list-select-box {
    display: flex;
    align-items: center;
    justify-content: center;
   
   
}

.list-type-select {
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #ccc;
    display: flex; /* Added to make it behave as a flex container */
    align-items: center;
    justify-content: center;
}



.quiz-show-box {
    display: flex;
    gap: 10px;
    margin-top: 20px;
}

.select-quiz-box { 
    position: absolute;
   width: 120px;
   background-color: #333;
   margin-top: 20px;
   margin-bottom: 50px;
   padding-bottom: 20px;
   /* height: 500px; */
  
}

.select-quiz-box h3 {
    margin-top: 10px;
    padding: 10px;
    margin-left: 10px;
    color: white;
    cursor: pointer;  
    border-radius: 10px;
    border: none;
    
}

.select-quiz-box h3:hover{
    background-color: #45a049;
}

.select-quiz-box  input{
    margin-left: 5px;
    padding: 10px;
}
.select-quiz-box lable{
    color: white;
}

.filter-box{
    position: relative;
    cursor: pointer;
}
.filter-box img{
    height: 50px;
    background-color: #caebcc;
    border-radius: 10px;
}

.quiz-list {
    
    padding: 20px;
    width: 100%;
    margin: auto;
    font-family: Arial, sans-serif;
    margin-top: 0px;
  }
  
  .quiz-list-head {
    display: flex;
    background-color: #ccc;
    justify-content: space-evenly;
    gap: 10px;
    width: 100%;
    padding: 10px;
  }
  
  .quiz-list-head label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 14px;
  }
  
  .quiz-list-head input {
    margin-top: 5px;
    padding: 5px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .quiz-list-head button {
    padding: 10px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .quiz-list-head button:hover {
    background-color: #45a049;
  }
  
  .quiz-list-show {
    margin-top: 20px;
  }
  
  .quiz-list-show h2 {
    margin-bottom: 10px;
  }
  
  .quiz-list-show ul {
    list-style-type: none;
    padding: 0;
  }
  
  .quiz-list-show ul li {
    padding: 5px 0;
    border-bottom: 1px solid #ddd;
  }
  
  .quiz-list-show ul li:last-child {
    border-bottom: none;
  }
  
  .quiz-list-show p {
    color: #666;
    font-size: 14px;
  }
  



#filter-btn {
    border-radius: 5px;
    padding: 5px;
    border-color: rgb(233, 151, 18);
}

.fa-trash {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 20px;
}


#viewQuest {
    position: absolute;
    right: 20px;
    font-size: 25px;
    background-color: #45a049;
    border-radius: 10px;
    bottom: 10px;
    padding: 10px;
    color: white;
}

#viewQuest:hover{
    background-color: #80c283;
}


.quiz-card-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); 
    
    gap: 20px; /* Space between cards */
    padding: 10px;
  }
  
  .quiz-card {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: transform 0.2s ease;
    
  }
  
  .quiz-card:hover {
    transform: scale(1.02); /* Slight scale on hover */
  }
  
  .quiz-details h3 {
    font-size: 1.2rem;
    margin-bottom: 5px;
  }
  
  .quiz-details p {
    font-size: 0.9rem;
    color: #555;
    margin: 2px 0;
  }
  
  .quiz-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  
  .add-question-btn,
  .delete-btn {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 8px 12px;
    border-radius: 5px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .add-question-btn:hover {
    background-color: #0056b3;
  }
  
  .delete-btn {
    background-color: #dc3545;
  }
  
  .delete-btn:hover {
    background-color: #a71d2a;
  }

@media(max-width:480px){
    .quiz-list-head {
        display: flex;
        flex-direction: column;
        gap: 15px; /* Space between elements */
        padding: 10px;
    }
    
    .list-select-box {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #333;
        width: 100%;
    }
    
    .list-type-select {
        padding: 8px;
        border-radius: 4px;
        border: 1px solid #ccc;
        width: 100%; /* Ensures the select box takes full width */
        max-width: 100%; /* Prevents the select dropdown from going outside the container */
        box-sizing: border-box; /* Ensures padding is included in width calculation */
    }

    input[type="date"] {
        padding: 8px;
        width: 100%;
    }
    
    .questList text { 
        padding-top: 15px;
    }

    #viewQuest {
        position: relative;
        margin-top: 20px;
        left: 10px;
        font-size: 16px;
    }

    .quiz-card-container {
        grid-template-columns: 1fr; /* Single-column layout for smaller screens */
      }
    
      .quiz-card {
        padding: 10px;
      }
    
      .quiz-actions button {
        font-size: 0.8rem;
        padding: 6px 10px;
      }
}
