/* Base Styles */
.job-alert-container {
    display: flex;
    flex-direction: column;
    font-family: Arial, sans-serif;
    background-color: #f8f9fa;
    min-height: 100vh;
  }
  
  /* Header */
  .job-alert-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #007bff;
    padding: 10px 15px;
    color: white;
    flex-wrap: wrap;
  }
  
  .tab {
    cursor: pointer;
    padding: 10px 15px;
    font-size: 16px;
    text-transform: uppercase;
    margin-right: 10px;
    background-color: transparent;
  }
  
  .selected-tab {
    background-color: #0056b3;
    font-weight: bold;
    border-radius: 4px;
  }
  
  .tabs-container {
    display: flex;
    flex-wrap: wrap;
  }
  
  /* Search Box */
  .search-box {
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 4px;
    overflow: hidden;
    margin-top: 10px;
  }
  
  .search-input {
    border: none;
    padding: 8px 10px;
    font-size: 14px;
    outline: none;
    width: 200px;
  }
  
  .search-button {
    background-color: #0056b3;
    border: none;
    padding: 8px 10px;
    cursor: pointer;
    color: white;
    display: flex;
    align-items: center;
  }
  
  .search-button:hover {
    background-color: #003d82;
  }
  
  .search-button i {
    font-size: 14px;
  }
  
  /* Body Layout */
  .job-alert-body {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    padding: 20px;
  }
  
  /* Sidebar Styles */
  .job-alert-side-cont1 {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .job-alert-sidebar, .job-alert-filter-sidebar {
    width: 25%;
    min-width: 200px;
    padding: 15px;
    background-color: #f1f1f1;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .job-alert-sidebar h3, .job-alert-filter-sidebar h3 {
    font-size: 18px;
    color: #333;
    margin-bottom: 10px;
  }
  
  .job-alert-sidebar ul, .job-alert-filter-sidebar ul {
    list-style: none;
    padding: 0;
  }
  
  .job-alert-sidebar li, .job-alert-filter-sidebar li {
    padding: 10px;
    cursor: pointer;
    font-size: 14px;
    color: #333;
    border-radius: 4px;
    margin-bottom: 5px;
  }
  
  .job-alert-sidebar li:hover, .job-alert-filter-sidebar li:hover {
    background-color: #e2e2e2;
  }

  .statewise-job {
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    position: relative; /* Necessary for proper positioning of child dropdown */
  }
  
  .statewise-job .arrow {
    font-size: 12px;
    margin-left: 10px;
    transition: transform 0.3s ease; /* Smooth transition for rotation */
  }
  
  .statewise-job .arrow.open {
    transform: rotate(180deg);
  }

  .sidebar-item {
    padding: 10px;
    cursor: pointer;
    font-size: 14px;
    color: #333;
    border-radius: 4px;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .sidebar-item:hover {
    background-color: #e2e2e2;
  }
  
  /* Highlight the selected sidebar item */
  .selected-sidebar-item {
    background-color: #007bff;
    color: #fff;
  }
  
  /* Dropdown List */
  .state-list {
    margin-top: 5px;
    padding-left: 20px;
    list-style: circle;
    animation: slideDown 0.3s ease; /* Smooth dropdown animation */
    position: relative; /* Positioned relative to the sidebar */
    background-color: #f9f9f9; /* Optional: To make it visually distinct */
    border: 1px solid #ddd; /* Optional: For a bordered dropdown */
    border-radius: 4px; /* Optional: Rounded corners */
    z-index: 1; /* Ensure it sits above other elements */
    padding: 10px 15px;
  }
  
  .state-item {
    padding: 5px 0;
    cursor: pointer;
    font-size: 13px;
    color: #333;
  }
  
  .state-item:hover {
    color: #007bff;
  }
  
  /* Animation */
  @keyframes slideDown {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .active-filter {
    background-color: #007bff;
    color: white;
  }
  
  /* Main Content */
  .job-alert-content {
    flex: 1;
    padding: 20px;
    min-width: 300px;
    
  }
  
  .job-alert-content h2 {
    color: #007bff;
    margin-bottom: 10px;
  }
  
  .job-alert-content p {
    font-size: 16px;
    line-height: 1.5;
  }

  /* Responsive Header */
.res-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #f8f8f8;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.res-dropdown-container {
  flex: 1;
  max-width: 300px;
}

.res-tab-dropdown {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  color: #333;
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.res-tab-dropdown:focus {
  border-color: #007bff;
}

.res-search-box {
  display: flex;
  align-items: center;
  max-width: 400px;
  margin-top: 10px;
}

.res-search-input {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  color: #333;
  outline: none;
}

.res-search-button {
  padding: 8px 12px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 5px;
}

.res-search-button:hover {
  background-color: #0056b3;
}

.res-search-button i {
  font-size: 18px;
}
.job-alert-res-header{
  display: none;
}

/* Sidebar for Filter */
.res-job-alert-filter-sidebar {
  display: none;
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.res-job-alert-filter-sidebar h3 {
  font-size: 18px;
  margin-bottom: 10px;
  color: #333;
}

/* Select dropdown styles */
.res-filter-dropdown {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  color: #333;
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.res-filter-dropdown:focus {
  border-color: #007bff;
}


/* Sidebar Container */
.res-job-alert-sidebar {
  display: none;
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.res-job-alert-sidebar h3 {
  font-size: 18px;
  margin-bottom: 10px;
  color: #333;
}

/* Dropdown for Sidebar Items */
.res-sidebar-dropdown {
  /* width: 100%; */
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  color: #333;
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

/* Statewise Dropdown Styles */
.res-statewise-dropdown {
  /* width: 100%; */
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  color: #333;
  margin-top: 10px;
  outline: none;
  cursor: pointer;
}

.res-sidebar-dropdown:focus, .res-statewise-dropdown:focus {
  border-color: #007bff;
}



  
  /* Responsive Design */
  @media screen and (max-width: 768px) {
    /* Header */
    .job-alert-header {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .tabs-container {
      flex-wrap: wrap;
      margin-bottom: 10px;
    }
  
    .search-box {
      width: 100%;
      margin-top: 10px;
    }
  
    .search-input {
      width: calc(100% - 40px);
    }
  
    /* Sidebars and Main Content */
    .job-alert-body {
      flex-direction: column;
    }
  
    .job-alert-sidebar, .job-alert-filter-sidebar {
      width: 100%;
      margin-bottom: 10px;
    }
  
    .job-alert-content {
      width: 100%;
      padding: 0px;
      
    }
    .job-alert-res-header{
      display: block;
    }
  
    .job-alert-header{
      display: none;
    }
    .res-job-alert-filter-sidebar{
      display: block;
      display: flex;
      flex-direction: column;
    }
  
    .job-alert-filter-sidebar{
      display: none;
    }

    .job-alert-sidebar{
      display: none;
    }

    .res-job-alert-sidebar{
      display: block;
    }

    .job-alert-side-cont1{
      flex-direction: row;
    }
    
  }
  
  @media screen and (max-width: 480px) {

    .job-alert-side-cont1{
      display: flex;
      flex-direction: row;
    }
    .res-job-alert-sidebar{
      /* width: 70%; */
    }

    .tab {
      font-size: 14px;
      padding: 8px 10px;
    }
  
    .search-input {
      font-size: 12px;
      padding: 6px 8px;
    }
  
    .search-button {
      padding: 6px 8px;
    }
  
    .job-alert-sidebar li, .job-alert-filter-sidebar li {
      font-size: 12px;
      padding: 8px;
    }
    .res-filter-dropdown{
      width: 120px;
    }

    .res-state-dropdown{
      width: 120px;
    }

   
  }

 
  